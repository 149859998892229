import {
	required,
	min,
	max
} from "vuelidate/lib/validators";

export default {
	data() {
		return {
			arCategorias: [],
			blLoading: true,
			blDialogExcluir: false,
			blExcluindo: false,
			blErro: false,
			blSalvando: false,
			datepicker: false,
			obForm: {
				id: 0,
				nome: "",
				src: "",
				id_categoria: null,
				subtitulo: 0,
				data: null,
				prioridade:0
			}
		};
	},
	validations: {
		obForm: {
			nome: {
				required
			},
			data: {
				required
			},
			src: {
				required
			},
			id_categoria: {
				required
			},
			prioridade: {
				required
			}
		}
	},
	created() {
		this.listarCategorias();

		if (this.$route.params.id) {
			this.obter(this.$route.params.id);
		} else {
			this.blLoading = false;
		}
	},
	methods: {
		listarCategorias() {
			this.$root.$api.get('categoria/listar').then(
				response => {
					this.arCategorias = response.retorno;
				}
			)
		},
		parseDate() {
            if (!this.obForm.data) return null;

            const [year, month, day] = this.obForm.data.split("-");
            return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
        },
		obter(id) {
			this.$root.$api.get("video/obter/" + id).then(response => {
				this.$root.$utils.preencheFormularios(
					this.obForm,
					response.retorno
				);

				this.obForm.data = response.retorno.data.split('/').reverse().join('-');

				this.blLoading = false;
			});
		},
		parseDate() {
			if (!this.obForm.data) return null;

			const [year, month, day] = this.obForm.data.split("-");
			return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
		},
		mensagemErro(strCampo) {
			return this.$utils.handleErrors(strCampo, this);
		},
		validarFormulario() {
			this.blErro = false;

			this.$v.obForm.$touch();

			if (this.$v.obForm.$invalid) {
				this.blErro = true;
			}
		},
		submit() {
			this.validarFormulario();

			if (this.blErro) {
				return false;
			}

			this.blSalvando = true;

			this.$root.$api
				.post("video/salvar", this.obForm)
				.then(response => {
					this.blSalvando = false;

					this.$router.push({
						name: "admin-videos"
					});
				});
		},
		excluir() {
			this.blExcluindo = true;

			this.$root.$api
				.delete("video/excluir/" + this.obForm.id)
				.then(response => {
					this.blDialogExcluir = false;

					this.$router.push({
						name: "admin-videos"
					});
				});
		}
	}
};